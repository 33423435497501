import { useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";

const KYCUtilityBill = ({ onClose, onClickDownload, kycUtility }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleMouseWheel = (e) => {
    if (e.deltaY > 0) {
      // Zoom out
      setZoomLevel((prevZoom) => (prevZoom > 0.5 ? prevZoom - 0.1 : 0.5));
    } else {
      // Zoom in
      setZoomLevel((prevZoom) => (prevZoom < 3 ? prevZoom + 0.1 : 3));
    }
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    setZoomLevel((prevZoom) => (prevZoom < 3 ? prevZoom + 0.1 : 3));
  };

  const handleLeftClick = () => {
    setZoomLevel((prevZoom) => (prevZoom > 0.5 ? prevZoom - 0.1 : 0.5));
  };

  return (
    <div
      className="fixed inset-0 bg-black-fade flex items-center justify-center z-50"
      onWheel={handleMouseWheel}
      onContextMenu={handleRightClick}
      onClick={handleLeftClick}
    >
      <div
        className={`bg-white rounded-xl w-[40%] max-w-[80%] max-h-[90vh] p-4 relative shadow-lg transform transition-all duration-300 ${
          isVisible ? "scale-100 opacity-100" : "scale-75 opacity-0"
        }`}
      >
        <div className="absolute top-4 right-4 cursor-pointer">
          <IoCloseCircle
            size={24}
            style={{ color: "black" }}
            onClick={() => {
              setIsVisible(false);
              setTimeout(onClose, 300);
            }}
          />
        </div>
        <h3 className="text-xs font-medium text-center pb-4">
          Utility Bill Upload
        </h3>

        <div className="flex w-full h-auto justify-center items-center overflow-hidden">
          <img
            src={kycUtility}
            alt="Utility Bill here"
            className="w-[70%] transition-transform duration-300"
            style={{ transform: `scale(${zoomLevel})` }}
          />
        </div>

        <div className="text-right mt-4">
          <button
            onClick={onClickDownload}
            className="bg-transparent w-[100px] py-2 rounded-3xl font-medium text-xs hover:bg-green-600 transition"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};
export default KYCUtilityBill;
